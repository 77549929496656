<template>
  <v-container
    fluid
  >
    <v-card
      class="px-16"
      :style="`height: ${($vuetify.breakpoint.height - 72).toString() + 'px'}; overflow-x: hidden;`"
    >
      <div>
        <v-overlay
          v-if="loading"
          absolute
          :value="loading"
          opacity="1"
          color="white"
          class="full-screen"
          text-xs-center
        >
          <div class="d-flex flex-column justify-center align-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
            <img
              src="@/assets/Lavirra_logo_SVG.svg"
              height="28px"
              width="auto"
              class="mt-6"
            >
            <span
              class="mt-4 ml-2"
              :style="`font-size: 15px; color: ${$vuetify.theme.themes.light.primary};`"
            >
              {{ message }}
            </span>
          </div>
        </v-overlay>
        <v-row>
          <v-col cols="4">
            <v-card
              elevation="8"
              class="mt-8 sticky-card"
              max-width="600"
              :style="`height: ${($vuetify.breakpoint.height - 172).toString() + 'px'}; overflow-x: auto;`"
            >
              <v-card-title
                class="justify-center"
              >
                <h3 style="color: #234e79;">
                  {{ $t('common|law_firm') }}/{{ $t('company|company') }}
                </h3>
              </v-card-title>
              <v-card-text
                class="text-center"
              >
                <div>
                  <h4>{{ account && account.subscriptionStatus === 'beta' ? '0' : cfg.preices.companyAccountPreis }}€/{{ $t('common|month') }}/{{ $t('members|seat') }}</h4>
                  <span v-if="account && account.subscriptionStatus === 'beta'">{{ $t('expressions|for_beta_users') }}</span>
                  <span v-else>{{ $t('members|billed_montly') }}</span>
                </div>
                <div
                  class="mt-8"
                  style="font-weight: bold;"
                >
                  {{ $t('common|total') }}: {{ getTotal() }}
                </div>
              </v-card-text>

              <div class="element-position ml-4">
                <v-divider class="px-2 mb-4" />
                <v-icon>mdi-check</v-icon>
                <a
                  class="ml-2"
                  href="https://lavvira.net"
                  target="_blank"
                >
                  {{ $t('expressions|all_core_features') }}
                </a><br>
                <v-icon>mdi-check</v-icon>
                <a
                  class="ml-2"
                  href="https://lavvira.net"
                  target="_blank"
                >
                  {{ $t('expressions|all_advanced_features') }}
                </a><br>
                <v-icon>mdi-check</v-icon>
                <span class="ml-2">{{ cfg.storageData.companyStorage }} {{ $t('common|storage') }}</span><br>
                <v-icon>mdi-check</v-icon>
                <span class="ml-2">{{ $t('common|premium_support') }}</span>
              </div>
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-toolbar
              flat
              width="100%"
              :rounded="false"
              class="form-toolbar"
            >
              <v-toolbar-title class="mt-4">
                {{ $t('company|create') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-form
              ref="formRef"
            >
              <v-card-text>
                <v-row
                  class="no-gutters"
                >
                  <v-col cols="9">
                    <v-text-field
                      v-model="companyName"
                      outlined
                      dense
                      :label="$t('clients|company_name')"
                      :hint="$t('company|example_name')"
                      persistent-hint
                      :rules="rules"
                      required
                      clearable
                      class="mt-2 mr-3 mb-8"
                    />
                    <v-checkbox
                      v-model="betaCheckbox"
                      :label="$t('expressions|beta_user_promo_key')"
                      class="mt-n3"
                    />
                    <v-text-field
                      v-if="betaCheckbox"
                      v-model="betaKey"
                      dense
                      outlined
                      required
                      clearable
                      :label="$t('expressions|enter_beta_code_here')"
                      :error-messages="betaKeyErrors"
                      class="mb-4 mr-3"
                      @input="$v.betaKey.$touch()"
                      @blur="$v.betaKey.$touch()"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="seats"
                      :label="$t('members|seats_amount')"
                      type="number"
                      minlength="1"
                      :rules="[(v) => !!v || 'Amount must be at least 1']"
                      required
                      outlined
                      dense
                      class="mt-2"
                      oninput="if(this.value <= 0) this.value = 1;"
                    />
                  </v-col>
                </v-row>

                <h4 class="ml-1">
                  {{ $t('members|invite_more_members') }} {{ '(' + $t('common|optional') + ')' }}
                </h4>
                <div
                  class="ml-1 mb-6"
                >
                  {{ $t('expressions|add_members_later') }}
                </div>
                <v-row
                  class="no-gutters"
                >
                  <v-col cols="9">
                    <v-text-field
                      v-model="account.email"
                      outlined
                      dense
                      :label="$t('user|email')"
                      :hint="$t('user|enter_valid_email')"
                      persistent-hint
                      clearable
                      :disabled="true"
                      :append-icon="'mdi-trash-can'"
                      class="mr-3 my-2"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :label="'AdminOwner'"
                      :hint="$t('members|user_role')"
                      persistent-hint
                      :disabled="true"
                      dense
                      outlined
                      class="mr-2 my-2"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-for="(input, index) in memberFields"
                  :key="`memberInput-${index}`"
                  class="no-gutters"
                >
                  <v-col cols="9">
                    <v-text-field
                      v-model="input.email"
                      outlined
                      dense
                      :label="$t('user|email')"
                      :hint="getTheRightHint(index, memberFields)"
                      persistent-hint
                      :rules="[(v) => !!v || $t('user|email_required')]"
                      required
                      clearable
                      :disabled="input.disabled"
                      :append-icon="'mdi-trash-can'"
                      class="mr-3 my-2"
                      @click:append="removeField(index, memberFields)"
                      @input="checkEmailExistence(index, memberFields)"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="input.userRole"
                      :items="userRoles"
                      :label="$t('members|user_role')"
                      :hint="$t('common|field_required')"
                      persistent-hint
                      :rules="[(v) => !!v || $t('user|role_required')]"
                      required
                      :disabled="input.disabled"
                      dense
                      outlined
                      class="mr-2 my-2"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-btn
                rounded
                outlined
                small
                color="primary"
                class="mb-2 mt-4"
                @click="addField()"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  mdi-account-plus
                </v-icon>
                {{ $t('members|invite_new_member') }}
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                rounded
                small
                :disabled="hasPermission === false"
                min-width="150"
                class="mt-2"
                :loading="loading"
                @click="submit"
              >
                {{ $t('payments|continue_to_checkout') }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <template>
          <StripeCheckout
            ref="checkoutRef"
            :pk="publishableKey"
            :session-id="sesId"
            @loading="v => loading = v"
          />
        </template>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import autobahn from 'autobahn'
import axios from '@/plugins/axios'
import cfg from '@/config'

export default {
  components: {
    StripeCheckout
  },
  mixins: [validationMixin],
  validations: {
    betaKey: { required, minLength: minLength(20) }
  },
  props: {
    activeSlide: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      sesId: null,
      companyName: null,
      loading: false,
      error: null,
      rules: [(v) => !!v || this.$t('fields|required')],
      memberFields: [],
      userRoles: [
        'Regular',
        'Advanced',
        'Admin',
        'AdminOwner'
      ],
      subscriptionType: '',
      hasPermission: false,
      seats: 1,
      message: '',
      cfg,
      betaCheckbox: false,
      betaKey: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    betaKeyErrors () {
      const errors = []
      if (!this.$v.betaKey.$dirty) return errors
      !this.$v.betaKey.required && errors.push(this.$t('warning|beta_key_required'))
      !this.$v.betaKey.minLength && errors.push(this.$t('warning|beta_key_length'))
      return errors
    }
  },
  watch: {
    companyName (value) {
      if (value && value.length > 0) {
        this.hasPermission = true
      } else if (value && value.length <= 0) {
        this.hasPermission = false
      } else if (value === null) {
        this.hasPermission = false
      }
    },
    memberFields: {
      handler (value) {
        if (!value.length && this.companyName && !this.betaCheckbox) {
          this.hasPermission = true
        }
        if (!value.length && this.companyName && this.betaCheckbox && this.betaKey) {
          this.hasPermission = true
        }
        if (value && value.length) {
          this.hasPermission =
          this.memberFields.length !== 0 &&
          this.memberFields.every(f => f.email !== null && (f.email.length > 0 && f.userRole) && f.verifiedEmail === 'email_exists')
        }
        const duplicates = this.memberFields.map((el, i) => {
          return this.memberFields.find((element, index) => {
            if (i !== index && element.email && element.email.length && element.email === el.email) {
              return el
            }
          })
        }).filter(Boolean)
        if (duplicates.length) {
          this.hasPermission = false
          this.addToast({
            title: this.$t('expressions|duplicate_emails'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      },
      deep: true
    },
    betaCheckbox (val) {
      if (val && !this.betaKey) this.hasPermission = false
      if (!val) this.betaKey = null
    },
    betaKey (val) {
      if (this.betaCheckbox && !val) this.hasPermission = false
      else if (this.betaCheckbox && val && this.companyName) this.hasPermission = true
    }
  },
  methods: {
    ...mapActions({
      addCompany: 'company/addCompany',
      addToast: 'toasts/addToast'
    }),
    getTotal () {
      if (this.account && this.account.subscriptionStatus === 'beta') return '0' + ' ' + '€'
      else return (Number(this.seats) * Number(cfg.preices.companyAccountPreis)).toString() + ' ' + '€'
    },
    addField () {
      const member = {
        email: '',
        userRole: '',
        verifiedEmail: 'not_tested'
      }
      this.memberFields.push(member)
      this.seats++
    },
    removeField (index, memberFields) {
      memberFields.splice(index, 1)
      this.seats--
    },
    getTheRightHint (index, memberFields) {
      if (memberFields[index].verifiedEmail === 'not_tested') {
        return this.$t('expressions|enter_email_of_existing_lavvira_user')
      } else if (memberFields[index].verifiedEmail === 'email_exists') {
        return this.$t('common|email_exists')
      } else if (memberFields[index].verifiedEmail === 'email_does_not_exist') {
        return this.$t('common|email_does_not_exist')
      } else if (memberFields[index].verifiedEmail === 'user_has_already_been_invited') {
        return this.$t('expressions|user_has_already_been_invited')
      } else if (memberFields[index].verifiedEmail === 'participates_in_other_company') {
        return this.$t('expressions|user_participates_in_other_company')
      }
    },
    async checkEmailExistence (index, memberFields) {
      const emailToBeChecked = memberFields[index].email
      const payload = {
        email: emailToBeChecked
      }
      try {
        const { data } = await axios.post(`/emailExistenceCheck`, payload)
        if (data.success) {
          memberFields[index].verifiedEmail = 'email_exists'
        } else if (!data.success && data.msg && data.msg === 'already_invited') {
          memberFields[index].verifiedEmail = 'user_has_already_been_invited'
        } else if (!data.success && data.msg && data.msg === 'participates_in_other_company') {
          memberFields[index].verifiedEmail = 'participates_in_other_company'
        } else {
          memberFields[index].verifiedEmail = 'email_does_not_exist'
        }
      } catch (error) {
        console.error(error, 'email check')
      }
    },
    async submit () {
      this.message = this.$t('actions|creating_company')
      this.loading = true
      const payload = {
        companyName: this.companyName,
        members: this.memberFields,
        seats: this.seats,
        betaCheckbox: this.betaCheckbox,
        betaKey: this.betaKey
      }
      try {
        await this.addCompany(payload)
        if (this.account && this.account.subscriptionStatus && this.account.subscriptionStatus !== 'beta') {
          this.message = this.$t('payments|proceed_to_payment')
          this.paymentProcessing()
        } else {
          this.$router.push('/company-success')
          this.loading = false
        }
      } catch (e) {
        this.error = e
        this.loading = false
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === 'Key not found') {
          this.addToast({
            title: this.$t('warning|key_is_not_valid_enter_valid_key'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
        console.error(this.error, 'error')
      }
    },
    async paymentProcessing () {
      let result = null
      let n = 0
      try {
        var connection = new autobahn.Connection({
          url: process.env.VUE_APP_CROSSBAR_PATH,
          realm: process.env.VUE_APP_CROSSBAR_REALM
        })
        connection.onopen = async (session) => {
          const makeSessionCall = async (account, memberFields) => {
            const _res = await session.call('lavvira.stripe_controller.create_payment_session',
              [
                account.companyId,
                'company',
                this.seats
              ])
            return _res
          }
          // try 5 times to get the sessionId from backend
          while (n <= 5) {
            result = await makeSessionCall(this.account, this.seats)
            const parsedRes = JSON.parse(result)
            if (parsedRes && !parsedRes.stripe_error) n = 6
            else n++
          }
          const res = JSON.parse(result)
          if (res && !res.stripe_error) {
            const res = JSON.parse(result)
            this.sesId = res.session_id
            setTimeout(() => {
              this.loading = false
              this.$refs.checkoutRef.redirectToCheckout()
            }, 3000)
          } else {
            this.loading = false
            this.addToast({
              title: this.$t('error|something_went_wrong'),
              color: 'white',
              snackbarColor: 'error'
            })
            this.$router.push('/members')
          }
        }
        connection.onclose = function (reason, details) {
          // handle connection lost
          console.log(reason, details, 'connection.onclose')
        }
        connection.open()
      } catch (e) {
        console.error(e, 'WAMP ERROR')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .form-toolbar {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .sticky-card {
    position: sticky;
    top: 33px;
    left: 0;
    z-index: 1;
  }
  .element-position {
    position: absolute;
    width: 90%;
    bottom: 30px;
  }
  .full-screen {
    height: 100%;
    width: 100%;
  }
</style>
